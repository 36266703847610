:root {
  --primary2: #3eb5ab;
  --base: #fff;
  --text: #000;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

.main-app-container-fixed-height {
  height: calc(100vh - 92px);
}

.body {
  zoom: 80%;
}

.container {
  height: 100%;
}

body.has-webview .app-main-body-container {
  display: flex;
  height: 100%;
  padding: 20px 0;
}

body.has-webview .app-main-header {
  display: flex;
}

.app-main-header {
  display: none;
}

body.has-webview .app-main-footer {
  display: none;
}

.app-main-footer {
  display: flex;
  height: 90px;
}

body.has-webview .app-main-sidebar {
  display: block;
}

.app-main-sidebar {
  display: none;
}

/* global classes */

.in-active {
  display: none !important;
  transition: all 0.3s ease-in-out;
}

.no-hover {
  pointer-events: none;
}

button:focus {
  outline: 0;
}

.mt120 {
  margin-top: 120px;
}

a {
  text-decoration: none;
  color: var(--primary2);
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

a:hover {
  color: #3eb5ab;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  /* font-size: 18px; */
  /* line-height: 30px; */
  /* letter-spacing: 0.05em; */
}

.my-account-link {
  color: #3eb5ab;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.05em;
}

.btn-container-disable {
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  /* height: 40px; */
  height: 35px;
  /* color: var(--primary2); */
  color: lightgrey;
  /* background-color: #fff; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* transition: .5s; */
  cursor: not-allowed;
  margin-top: 20px;
}

.btn-container {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  /* height: 40px; */
  height: 35px;

  color: white;
  /* background-color: #2a9b8d; */
  background-color: #3eb5ab;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* transition: .5s; */
  cursor: pointer;
  margin-top: 20px;
}

/* border: 1px solid var(--primary2);
color: var(--primary2);
background-color: #fff; */

.btn-container-white {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  /* height: 40px; */
  height: 35px;

  color: #3eb5ab;
  /* background-color: #2a9b8d; */
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* transition: .5s; */
  cursor: pointer;
  margin-top: 20px;
}

.btn-container.active {
  background-color: var(--primary2);
  color: white;
  /* transition: all .5s ease-in-out; */
}

.btn-container:hover {
  /* background-color: #fff; */
  background-color: #2a9b8d;
  color: #fff;
  /* color: var(--primary2); */
}

.btn-containerEnabled {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  /* height: 40px; */
  height: 35px;
  color: white;
  background-color: var(--primary2);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* transition: .5s; */
  cursor: pointer;
}

.btn-containerEnabled-remove-credit-card {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  /* height: 40px; */
  height: 35px;
  color: white;
  background-color: var(--primary2);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* transition: .5s; */
  cursor: pointer;
}

.btn-containerEnabled-remove-credit-card:hover {
  background-color: #2a9b8d;
  color: white;
}

.bg-pattren {
  background-image: url("../assets/images/bg.png");
  width: 100%;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100% 75%;
}

.bgCardContainer {
  width: 700px;
  /* width: 454px; */
  margin: 0 auto;
  position: relative;
  top: 50px;
}

.forgot-password-container {
  width: 454px;
  /* width: 600px; */
  margin: 0 auto;
  position: relative;
  top: 50px;
}

.card-top-img {
  top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
}

.card-top-img img {
  width: 150px;
  margin-bottom: 5px;
}

.bg-card-inner-container {
  padding: 0 148px;
}

.bgCard {
  padding: 35px 0;
  margin: 0 auto;
  width: "100%";
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  margin-bottom: 100px;
}

.bgCard .form {
  margin: 60px 0;
}

.forgot-password-bgCard {
  /* padding: 35px 0; */
  padding-bottom: 35px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  margin-bottom: 100px;
  /* padding-left: 3%;
    padding-right: 3%; */
}

.forgot-password-bgCard .form {
  margin: 60px 0;
}

.bgCard h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  width: 130%;
  display: flex;
  justify-content: center;
  margin-left: -15%;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
}

.row .form-grop:first-child:not(:last-child) {
  margin-right: 25px;
}

.form-grop {
  flex: 1;
}

.signup-form .form-grop,
#login-form .form-grop {
  margin-bottom: 10px;
}

.form-grop input::-webkit-input-placeholder,
.card-form-group input::-webkit-input-placeholder {
  font-weight: 400;
  color: #c4c4c4;
}

.form-grop,
.card-form-group input::-moz-placeholder {
  font-weight: 400;
  color: #c4c4c4;
}

.form-grop,
.card-form-group input::-ms-input-placeholder {
  font-weight: 400;
  color: #c4c4c4;
}

.form-grop label,
.card-form-group label {
  font-family: "Quicksand";
  font-style: normal;
  /* font-weight: 600; */
  /* font-size: 18px; */
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  display: block;
  margin-bottom: 0rem;
}

.card-form-group label {
  margin-bottom: 10px;
}

.card-form-group label {
  margin-bottom: 10px;
}

.form-grop p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  line-height: 22px;
  color: #000000;
}

.add-payment-card {
  width: 646px;
  margin-left: 30px;
}

.form-grop p.billing-check {
  font-size: 16px;
}

.form-grop input,
.card-form-group {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;

  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  padding: 10px 0 5px 0px;
}

.add-card-page .form-grop input,
.add-card-page .card-form-group {
  width: 60%;
}

.add-card-page .card-form-group.address input {
  width: 100%;
}

.card-form-group-forSelect {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-right: 195px;
  border-bottom: 1px solid #c4c4c4;
  width: 30%;
  padding: 10px 0 5px 0px;
  margin-bottom: 30px;
}

.label {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}

.form-grop .input-pass {
  border-bottom: 1px solid #c4c4c4 !important;
  /* width: 80%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-grop .pass-strength {
  margin-top: 10px;
}

.pass-strength span {
  position: relative;
  bottom: 2px;
}

.pass-strength {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin-left: 18px;
  bottom: 2px;
  position: relative;
  display: inline-block;
}

.checks-container>div {
  display: flex;
  align-items: center;
  /* margin: 5px 0; */
}

.not-sm {
  display: block;
  margin-left: 25px;
}

.sm {
  display: none;
}

.signup-form {
  max-width: 500px;
  margin: 60px auto !important;
  margin-bottom: 40px !important;
}

#login-form {
  max-width: 412px;
  margin: 15px auto;
}

#login-form input {
  width: 100%;
}

.forgot-pass {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 10px;
}

.forgot-passLink {
  font-size: 14px;
  font-weight: 500;
  color: #c4c4c4;
  text-decoration: none;
}

.forgot-passLink:hover {
  color: #3eb5ab !important;
  text-decoration: none;
}

.route-change-tagline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.route-change-tagline>span {
  font-size: 14px;
  font-weight: 300;
  color: #c4c4c4;
}

.route-change-taglineLink {
  font-size: 14px;

  font-weight: 500;
  color: #3eb5ab;
  text-decoration: none;
}

.route-change-taglineLink:hover {
  color: #666666 !important;
  text-decoration: none;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  height: 100px;
}

.display-flexinput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.app-header-left {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #000;
  display: flex;
  align-items: center;
}

.app-header-left>div {
  margin-right: 15px;
}

/* Account Page */

.page-account {
  color: var(--text);
}

.account-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px auto;
  max-width: 400px;
  flex-wrap: wrap;
}

.account-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.account-detail h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.account-detail span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
}

.account-cards .account-card {
  box-sizing: border-box;
  /* max-width: 147px; */
  max-width: 48%;
  height: 157px;
  max-height: 157px;
  border: 1px solid rgba(196, 196, 196, 0.1);
  box-shadow: 0px 2px 6px 1px rgba(196, 196, 196, 0.6);
  border-radius: 20px;
  background-color: var(--base);
  transition: all 0.3s ease;
  transform: scale(1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* flex: 1; */
  /* margin:3%; */
  margin-top: 4%;
  width: 45%;
  /* margin: 15px; */
  /* width: calc(50% - 60px); */
}

.account-cards .account-card:last-child {
  margin-left: 8%;
}

.account-cards .account-card:hover {
  transition: all 0.3s ease;
  transform: scale(1.1);
}

.account-card svg,
.account-card img {
  width: 50px;
  height: 50px;
}

.account-card span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  /* color: var(--text); */
}

/* Main Footer */

.app-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 83px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 0px 0px 20px 20px;
  box-sizing: border-box;
}

.app-footer .footer-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 1;
  cursor: pointer;
  flex: 1;
}

.botom-nav-link {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.botom-nav-link:hover {
  text-decoration: none;
}

.botom-nav-link>span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
  color: #666666;
  text-decoration: none !important;
}

.botom-nav-link>span :hover {
  text-decoration: none !important;
}

.app-footer .footer-tab .active span {
  color: var(--primary2);
  text-decoration: none;
}

.app-footer .footer-tab .active svg path {
  fill: var(--primary2);
}

.app-footer .footer-tab:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.everything-centered-of-page {
  height: 100vh;
  /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.loader-animation {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.email-sent-msg {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}

.resend-confirmation-code {
  font-family: "Quicksand";
  background-color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 167% */
  text-align: center;
  letter-spacing: 0.05em;
  color: #2a9b8d;
}

.check-mark-filled {
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3eb5ab;
}

.check-mark-filled-small {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3eb5ab;
}

.check-mark-error-filled-small {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.code-verication-email {
  font-size: 30px;
  /* width: 600px; */
  height: 40px;
  /* left: 104px; */
  /* top: 359px; */
  background: #f0f0f0;
  box-shadow: 0px 2px 2px rgba(157, 157, 157, 0.4);
  border-radius: 9px;
}

.code-verication-email-error {
  color: #ff3d00;
  font-size: 30px;
  width: 600px;
  height: 40px;
  /* left: 104px; */
  /* top: 359px; */
  background: #f0f0f0;
  border: 1px solid #ff3d00;
  box-shadow: 0px 2px 2px rgba(157, 157, 157, 0.4);
  border-radius: 9px;
}

.dashboard-background-color {
  height: 600px;
  border-top: 5px solid #3eb5ab;
  /* box-sizing: border-box; */
  box-sizing: content-box;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  padding: 60px 40px 30px;
}

.profile-dashboard-desktop-container {
  /* height: 100%; */
  border-top: 5px solid #3eb5ab;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  padding: 60px 40px 30px;
}

.dasboard-header-top-padding {
  /* padding-top: 2%; */
}

.dasboard-bottom-calendar-top-padding {
  padding-top: 15%;
}

.dasboard-add-to-calendar-top-padding {
  padding-top: 1%;
}

.dashboard-upcoming-pick-up-top-padding {
  /* padding-top: 5%; */
  padding-top: 50px;
}

.forgot-password-text-button {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #a5a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.modal-container-with-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.hamburger-design-popup-text-body {
  padding-top: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #666666;
}

.forgot-password-profile-modal-container {
  padding-top: 10%;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.forgot-password-send-container {
  padding-top: 4%;
  padding-bottom: 5%;
}

.password-updated-font {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;
  color: #000000;
}

.password-updated-response-msg-font {
  margin-left: 30px;
  margin-right: 30px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  /* letter-spacing: 1px; */
  /* identical to box height */
  text-align: center;
  color: #666666;
}

.password-updated-code-response-msg-font {
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  /* letter-spacing: 1px; */
  /* identical to box height */
  text-align: center;
  color: #666666;
}

.password-updated-input-font {
  /* font-size: 18px;
    font-family: 'Quicksand';
    font-style: normal; */
}

.password-updated-input {
  margin-left: 30px;
  margin-right: 30px;
}

.forgot-update-input-size {
  border-bottom: 0px !important;
  font-size: 18px;
  border-bottom-width: 0;
}

.forgot-update-success-msg {
  padding-top: 10%;
  font-size: 20px;
}

.forgot-update-msg-size {
  padding-top: 7%;
  font-size: 18px;
}

.forgot-password-msg-size {
  font-size: 18px;
}

.forgot-password-header-size {
  font-size: 20px;
}

.forgot-password-error-code {
  font-size: 18px;
  color: #ff3d00;
}

.update-password-code-input-button {
  padding-top: 45px;
  padding-bottom: 20px;
}

.update-password-button-container {
  padding-top: 15px;
  padding-bottom: 25px;
}

.update-password--header-padding {
  padding-top: 10px;
  font-size: 14px;
}

.update-password-top-padding-20px {
  padding-top: 20px;
}

.update-password-input-padding-20px {
  padding-right: 20px;
  padding-left: 20px;
}

.modal-design-popup {
  margin: 0 auto;
  width: 339px;
  height: 400px;
  left: 25px;

  padding-top: 30px;

  background: #ffffff;
  border-radius: 20px;

  background-color: white;
  border-radius: 20px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hamburger-background-left {
  /* margin: 0 auto; */
  width: 100%;
  height: 100%;
  margin-left: 1px;
  /* background: #ffffff; */
  /* background-color: white; */
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}

.hamburger-body {
  margin: 0 auto;
  width: 339px;
  height: 100%;
  margin-right: 1px;
  padding-top: 30px;
  background: #ffffff;
  /* border-radius: 20px; */
  background-color: white;
  z-index: 1;
}

.modal-design-popup-remove-credit-card {
  margin: 0 auto;
  width: 485px;

  background: #ffffff;
  border-radius: 20px;

  background-color: white;
  border-radius: 20px;
  z-index: 1;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.close-modal-button {
  padding-right: 0px;
  padding-top: 0%;
  font-size: 25px;
}

.close-modal-button-remove-credit-card {
  padding-right: 10px;
  padding-left: 0px;
  padding-top: 0.5%;
  font-size: 25px;
}

.forgot-password-code-close-modal-button {
  padding-right: 1%;
  font-size: 25px;
}

.forgot-password-close-modal-button {
  /* padding-top: 4%; */
  /* padding-left: 10px;
    font-size: 18px;
    padding-bottom: 1px; */
  /* float: right; */
  font-size: 18px;
  position: relative;
  top: -4px;
  cursor: pointer;
}

.eeee {
  text-align: right;
}

.top-padding-60px {
  padding-top: 60px;
}

.top-padding-40px {
  padding-top: 40px;
}

.top-padding-30px {
  padding-top: 30px;
}

.top-padding-20px {
  padding-top: 20px;
}

.top-padding-15px {
  padding-top: 15px;
}

.top-padding-10-percent {
  padding-top: 10%;
}

.dashboard_calendar_container {
  box-sizing: border-box;
  width: 264px;
  left: 70px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.dashboard_calendar_container_no_pickup_date {
  box-sizing: border-box;
  width: 264px;
  height: 220px;
  left: 70px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.calendar-no-pickup-msg {
  padding-top: 26%;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;

  color: #000000;
}

.calendar-header-display {
  box-sizing: border-box;
  height: 50px;
  /* left: 70px; */
  /* top: 273px; */
  width: 264px;
  background: rgba(251, 3, 3, 0.8);
  border-radius: 20px 20px 0px 0px;
}

.merph {
  height: 86px;
  width: 250px;
  left: 149px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-greeting {
  padding-left: 3%;
  /* padding-bottom: 3%; */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  letter-spacing: 0.04em;

  color: #000000;
}

.dashboard-greeting-2 {
  padding-top: 1.5%;
  padding-left: 3%;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #666666;
}

.upcoming-pickup-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.1em;

  color: #4f4f4f;
  padding-bottom: 1%;
}

.pickup-add-to-calendar {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 5%;
  background-color: white;

  text-align: center;
  letter-spacing: 0.1em;

  color: #808080;
}

.calendar-month-font {
  padding-top: 4%;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 31px;
  text-align: center;

  color: #ffffff;
}

.calendar-day-number-font {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  font-size: 100px;
  line-height: 125px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #000000;
}

.calendar-day-text-font {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: #fb0303;
}

.dashboard-container {
  zoom: 80%;
}

@media screen and (max-width: 1200px) {
  .payment-card {
    width: 100% !important;
    margin: 20px auto;
  }
}

@media screen and (min-width: 1200px) {
  .dashboard-container {
    /* width: 90% !important; */
    max-width: 90% !important;
  }
}

@media screen and (min-width: 993px) {
  .monster-desktop-display {
    /* display: none; */
    /* visibility: hidden; */
  }
}

@media screen and (max-width: 991px) {
  .app-main-body-container {
    gap: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .payment-cards-data-group {
    border-top: 1px solid #c4c4c4;
    padding-top: 15px;
  }

  .bgCardContainer {
    width: 760px;
  }

  .forgot-password-container {
    width: 760px;
  }

  .bg-card-inner-container {
    padding: 0 40px;
  }

  .bgCard {
    padding: 60px 0;
    margin: 0 10px;
  }

  .forgot-password-bgCard {
    /* padding: 60px 0; */
    /* margin: 0 10px; */
  }

  .profileIcon {
    color: var(--primary2);
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .bgCardContainer {
    width: 470px;
  }

  .Service-div {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 190px !important;
    flex-direction: column;
  }

  .forgot-password-container {
    width: 470px;
  }

  .bgCard {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .bgCard .form {
    margin: 30px;
  }

  .forgot-password-bgCard {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .forgot-password-bgCard .form {
    /* margin: 30px; */
  }

  .pass-strength {
    font-size: 12px;
  }

  .form-grop .pass-strength {
    margin-top: 5px;
  }

  .profileIcon {
    color: var(--primary2);
    font-size: 17px;
  }

  .profile-dashboard-desktop-container {
    border-top: none;
    box-sizing: none;
    background: #ffffff;
    box-shadow: none;
    flex: 1;
    padding: 60px 0px 30px !important;
  }
}

@media screen and (max-width: 480px) {
  .summary-complete-btn-padding-top {
    padding-top: 15px !important;
  }

  .display-flexinput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-options-header {
    margin-right: 15px;
    margin-left: 15px;
  }

  .Service-mobile-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Service-mobile-div label {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: black;
  }

  .edit-button {
    background-color: transparent;
  }

  .address-cards .address-card {
    width: 87px !important;
    height: 100px !important;
  }

  .address-cards .address-card svg {
    height: 25px !important;
    width: 26px !important;
  }

  .address-cards .address-card.active label {
    font-size: 12px;
  }

  .address-cards .address-card-container {
    border-bottom-width: 1px !important;
  }

  .account-cards .account-card {
    box-sizing: border-box;
    max-width: 147px;
    /* max-width: 48%; */
    height: 147px;
    /* max-height: 157px; */
  }

  .Service-div {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 190px !important;
    flex-direction: column;
  }

  .services-text {
    display: none;
  }

  .serviceLi {
    font-size: 12px !important;
    /* margin-left: 10px; */
  }

  .serviceUl>ul>li {
    /* list-style: none; */
    line-height: 25px !important;
    justify-content: space-between;
    font-size: 12px;
    /* margin-right: 74px !important; */
  }

  .serviceUl>ul>li .update-my-plan-text {
    font-size: 12px !important;
  }

  .add-card-page .card-form-group {
    width: 100%;
  }

  .card-form-group-forSelect {
    margin-right: 195px;

    width: 100%;
  }

  .page-account {
    margin-bottom: 100px;
    color: var(--text);
  }

  .app-header-left {
    font-size: 20px;
  }

  .mobile-account-background {
    height: 150vh;
    background-color: rgba(62, 181, 171, 0.05);
    margin-right: -15px;
    margin-left: -15px;
  }

  .mobile-account-background .page-account {
    margin-right: 15px;
    margin-left: 15px;
  }

  .page-setting .account-cards .account-card {
    margin: 15px !important;
  }

  .add-payment-card {
    width: 80%;
    margin-left: 30px;
  }

  .hideDesktop {
    display: block !important;
    visibility: visible !important;
  }

  .hideMobile {
    display: none !important;
  }

  .top-padding-40-px-profile {
    padding-top: 40px;
  }

  .payment-card-data-group span.edit-span {
    /* margin-right: 44px; */
  }

  .app-main-content .payment-card-data-group .link svg {
    margin-left: 10px !important;
  }

  .account-card span {
    padding: 15px;
    font-weight: 500;
  }

  .row .form-grop:first-child:not(:last-child) {
    margin: 5px 10px;
  }

  .row.first-row .form-grop:first-child {
    margin-right: 10px;
  }

  .signupInput {
    margin-top: 18px;
  }

  .bgCardContainer {
    width: 100%;
  }

  .forgot-password-container {
    width: 100%;
  }

  .bg-card-inner-container {
    padding: 20px 0px;
  }

  .bgCard {
    padding: 0px 20px;
    padding-bottom: 40px;
  }

  .bgCard .form {
    margin: 20px;
    margin-bottom: 35px;
  }

  .forgot-password-bgCard {
    padding: 0px 5px;
    padding-bottom: 40px;
  }

  .forgot-password-bgCard .form {
    margin: 20px;
    margin-bottom: 35px;
  }

  .form-grop label {
    font-size: 14px;
  }

  .row {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  .form-grop p {
    font-size: 14px;
    margin-top: 0;
  }

  #login-form {
    margin-top: 50px;
  }

  .bgCard h1 {
    /* font-size: 20px !important;
        margin-top:30px;
        top: 25px;
        position: relative;
         */
    margin-bottom: -30px;
    margin-top: 40px;
    font-size: 20px !important;
  }

  .form-grop input {
    padding-top: 0;
    font-size: 16px;
    background-color: transparent;
    font-weight: 500;
    /* width: 95%; */
  }

  .pass-strength {
    font-size: 12px;
    /* margin-left: 10px !important; */
    /* line-height: ; */
  }

  .pass-strength-mob {
    margin-left: 10px !important;
  }

  .checks-container {
    margin: 0 10px !important;
  }

  .checks-container>div {
    margin: 0px;
  }

  .form-grop {
    margin: 5px 10px;
  }

  .form-grop .input-pass,
  .checks-container {
    width: 100%;
    /* max-width: 310px; */
    margin: 0 auto;
  }

  .row.row-sm-single {
    display: block;
    /* max-width: 310px; */
    margin: 0 auto;
  }

  .not-sm {
    display: none;
  }

  .sm {
    display: block;
  }

  .profileFirstNameInput {
    float: right;
    width: 94%;
  }

  .profileLastNameInput {
    width: 50%;
  }

  .profileTitleFont {
    font-family: "Quicksand";
    font-style: normal;
    font-size: 14px;
    color: #666666;
    display: block;
  }

  .profileIcon {
    color: var(--primary2);
    font-size: 17px;
  }

  .profileNamePosition {
    margin-bottom: -3%;
  }

  .profileTitlePosition {
    margin-bottom: -5%;
    margin-left: -1.2%;
  }

  .profileFirstNamePosition {
    padding-left: 10%;
  }

  .inputBetweenSpace {
    padding-top: 5%;
  }

  .FaCheckCircle-icon {
    margin-bottom: 7px;
  }

  .has-error {
    margin-left: 12px !important;
  }

  .summary-plan-title {
    margin-top: 40px !important;
  }
}

.profileIcon {
  color: var(--primary2);
}

.hideDesktop {
  display: none;
  visibility: hidden;
}

.padding-left-error-forgot-pw-email {
  padding-left: 25px;
}

.errorProfileMsgFont {
  color: red;
  font-size: 12px;
}

.pageHeaderFont {
  color: black;
  font-size: 20px;
}

.input-profile-style {
  color: black;
  font-size: 14px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

.update-password-icon {
  color: #3eb5ab;
  height: 35px;
  font-size: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  text-align: right;
}

.form-group .form-control {
  padding: 0;
}

.input-field-editable {
  border-bottom: 1;
  border-color: #c4c4c4;
}

.input-field-uneditable {
  border: none !important;
  background: transparent !important;
}

.blank-password {
  display: flex;
  align-items: baseline;
}

.has-error {
  color: #fb0303;
  margin-left: 0;
  margin-top: 0;
}

/*
* user settings
*/

.main-seaction {
  flex: 1;
  height: 100%;
}

.app-main-content {
  /* new change */
  min-height: 694px;
  padding: 0px 10px;

  /* position: relative;
    overflow: hidden; */
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}

.app-main-content .heading2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.app-main-content .link svg {
  /* margin-right: 11px; */
  margin-left: 20px;
  bottom: 2px;
  position: relative;
}

.app-main-content .link {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3eb5ab;
  cursor: pointer;
}

.section-bg-card {
  border-top: 5px solid #3eb5ab;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  /* new change */
  float: none !important;
}

.upcoming-date-side-section {
  margin-bottom: 15px;
  font-family: "Quicksand";
  text-align: center;
}

.upcoming-date-side-section>label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4f4f4f;
  margin-bottom: 10px;
}

.upcoming-date-side-section .calender-card h4 {
  background: rgba(251, 3, 3, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  font-size: 25px;
  line-height: 31px;
  color: #ffffff;
  text-transform: uppercase;
}

.upcoming-date-side-section .calender-card .calender-card-body {
  background-color: #fff;
}

.upcoming-date-side-section .calender-card .calender-card-body span {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #fb0303;
  text-align: center;
  display: block;
  margin-bottom: 23px;
}

.upcoming-date-side-section .calender-card .calender-card-body h2 {
  font-size: 100px;
  line-height: 120px;
  text-align: center;
  letter-spacing: 0.04em;
  font-weight: 300;
  color: #000000;
  margin-bottom: 0;
}

.upcoming-date-side-section .calender-card {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
}

.app-main-content aside,
.app-main-content .card-section-web {
  flex: 1;
}

.app-main-content .card-section-web {
  padding: 60px 80px 30px;
}

.app-main-content aside {
  max-width: 328px;
  /* overflow: scroll; */
}

.app-main-content aside .menu-options-section:nth-child(2) {
  margin-top: 36px;
}

.gutter {
  width: 32px;
}

.app-main-content section {
  flex: 1;
  /* overflow: scroll; */
}

.app-main-content h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  border-bottom: 1px solid #ededed;
  padding-bottom: 12px;
  margin-bottom: 10px;
}

.menu-options-section ul li svg {
  margin-right: 12px;
  width: 15px;
  height: 15px;
  color: #3eb5ab;
}

.menu-options-section ul li a.active {
  color: #3eb5ab;
  text-decoration: none;
  /* font-weight: bold; */
}

.menu-options-section ul li:last-child a.active {
  color: #666666;
}

.menu-options-section ul li:last-child a:hover {
  color: #3eb5ab;
}

.menu-options-section ul li,
.menu-options-section ul li a {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
}

.menu-options-section ul li {
  list-style: none;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.menu-options-section ul li:hover a {
  /* transition: all .3s ease-in-out; */
  /* opacity: .75; */
  color: #3eb5ab;
  text-decoration: none;
}

.menu-options-section ul li:first-child {
  margin-top: 20px;
}

.card-options-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
}

.payment-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.payment-card-active img {
  height: 37px;
  width: 61px;
  position: absolute;
  top: -15px;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.payment-card-active {
  position: absolute;
  top: -5px;
  right: 40px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.payment-card-active .badge span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.payment-card-active .badge {
  width: 56px;
  height: 60px;
  left: 795px;
  top: 329px;
  background: #fdc815;
  border-radius: 10px 10px 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-card {
  width: calc(50% - 22px);
  max-width: 375px;
  position: relative;
  margin-bottom: 15px;
}

.payment-card-container {
  width: 100%;
  height: 206px;
  position: relative;
  background: #1d8178;
  box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.payment-card>.payment-card-container.hasShadow.shadowActive {
  transform: rotate(-10deg);
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.payment-card>.payment-card-container.hasShadow {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  transform: rotate(0deg);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  /* transform: rotate(-10deg); */
}

.payment-card-container:hover {
  transition: all 0.3s ease-in-out;
  opacity: 0.95;
}

.payment-card-container.active {
  transition: all 0.3s ease-in-out;
  background: #164080;
}

.payment-card {
  margin-bottom: 40px;
}

.payment-card-active.active .showMonster {
  top: -30px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.payment-card-active.active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.payment-card-default {
  display: flex;
  justify-content: flex-end;
  margin: 15px 15px 0 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.payment-card-default.active {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.payment-card-data-group label {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
  flex: 1;
}

.payment-card-data-group span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  flex: 1;
  text-align: right;
}

.payment-card-data-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.payment-card-container h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2em;
  color: #ffffff;
}

.Service-div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* width: 364px; */
  /* max-width: 90%; */
}

.servicesText {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
}

.serviceUl>ul>li {
  list-style: none;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  /* margin-right: 50px; */
}

.serviceUl>ul>li .update-my-plan-text {
  font-size: 16px;
}

.payment-card-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.payment-card-data .payment-card-number {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  color: #ffffff;
  margin-bottom: 18px;
}

.payment-card-data .payment-card-meta {
  display: flex;
  justify-content: space-between;
}

.payment-card-data .payment-card-meta span {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.app-main-content section {
  display: none !important;
}

.app-main-content section.active {
  display: block !important;
  min-height: 880px;
  overflow-y: scroll;
}

/*
* Billing
*/

.mobile-layout-container {
  width: 90%;
  margin: auto;
}

.address-cards {
  display: flex;
  align-items: center;
  /* overflow: scroll; */
}

.address-cards .address-card-container {
  padding-bottom: 10px;
  border: 1px solid white;
  border-bottom-width: 3px;
  border-bottom-color: #fff;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card-container.active {
  border-bottom-color: #3eb5ab;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card {
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 115px;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card.active {
  background-color: #3eb5ab;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card.active label,
.address-cards .address-card.active svg path {
  color: #fff;
  fill: #fff;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card svg path {
  fill: #3eb5ab;
  transition: all 0.3s ease-in-out;
}

.address-cards .address-card svg {
  height: 41px;
  width: 44px;
}

.address-cards .address-card label {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #3eb5ab;
  transition: all 0.3s ease-in-out;
  display: block;
  margin-top: 20px;
  margin-bottom: 0;
}

.billing-address-field-section {
  margin: 26px 0;
}

.billing-address-field-section p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.billing-address-field-section label {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin-right: 19px;
}

body.has-webview .billing-detail-section {
  /* max-width: 80%; */
  padding: 0;
  border: unset;
  box-shadow: unset;
}

body.has-webview .billing-detail-section .form-grop label {
  font-size: 20px;
}

body.has-webview .billing-detail-section .form-grop {
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body.has-webview .billing-detail-section .form-grop input {
  background-color: #fff;
  font-size: 18px;
}

body.has-webview .billing-detail-section .form-grop .input-pass {
  padding: 0;
  border-bottom: 0 !important;
}

.billing-detail-section {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 16px;
}

/* 
.billing-detail-section .input-container {
    padding-bottom: 10px;
    padding-top: 10px;
} */

.billing-detail-section .padding-container-top {
  padding-top: 20px;
}

.billing-detail-section .form-grop {
  padding-top: 10px;
}

.billing-modal-container {
  margin: 30px 10px;
}

.billing-modal-container .form-grop {
  margin: 40px 0;
}

.billing-modal-container h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  top: 10px;
  position: relative;
  text-align: center;
  color: #000000;
}

.w500 {
  font-weight: 500 !important;
}

.w800 {
  font-weight: 600 !important;
}

.p20 {
  padding-right: 20px;
}

.menu-options-section li {
  font-weight: 400;
}

.edit-span {
  display: flex;
}

.edit-span span:first-child {
  min-width: 100%;
}

.payment-card-data-group input,
.payment-card-data-group select {
  text-align: right;
  /* border-bottom: 0px; */
}

.payment-card-data-group input:focus,
.payment-card-data-group select {
  border-bottom: 1px solid #c4c4c4;
}

.payment-card-data-group select:focus {
  border-bottom: 1px solid #c4c4c4;
}

.asterik {
  letter-spacing: 2px;
}

.error-modal-container,
.error-modal-container .error-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.error-modal-container .error-modal-body .error-modal-static {
  color: #fb0303;
  margin-bottom: 15px;
}

.error-modal-container .error-modal-body .error-modal-static span {
  text-align: center;
  display: block;
}

.error-modal-container .error-modal-body p {}

.add-address {
  width: 51px !important;
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.billing-detail-section .form-grop:last-child .input-pass {
  border-bottom: 0px !important;
}

.edit-span span p {
  margin-bottom: 0px;
}

.top-padding-40-px-profile {
  padding-top: 0;
}

.add-payment-card .card-form-group {
  margin-bottom: 30px;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #2a9b8d;
  padding: 5px;
}

.reactivate-subscription-btn {
    color: #3eb5ab;
    font-size: 20px;
}

.reactivate-subscription-btn:hover {
    cursor: pointer;
}

.padding-top-65px {
  padding-top: 65px;
}

.profile-desktop-font-lable {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #666666;
}

.profile-desktop-input-font {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.profile-desktop-lable-position {
  margin-top: 13px;
}

.profile-desktop-btn-position {
  padding: 30px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-desktop-header-font {
  padding-left: 15px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.profile-desktop-btn-container {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  height: 38px;
  color: var(--primary2);
  background-color: #fff;
  margin: 0 auto;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  text-decoration: none;
  transition: 0.5s;
  /* cursor: pointer; */
}

.button-without-background:not([disabled]) {
  border: 1px solid var(--primary2);
  color: var(--primary2);
  background-color: #fff;
}

.profile-desktop-btn-containerEnabled {
  border: 1px solid var(--primary2);
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  width: 250px;
  /* width: 260px; */
  height: 38px;
  color: white;
  background-color: var(--primary2);
  margin: 0 auto;
  /* display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; */
  transition: 0.5s;
  /* cursor: pointer; */
}

.icon-design {
  margin-right: 12px;
  width: 15px;
  height: 15px;
  color: #3eb5ab !important;
}

body.has-webview .billing-detail-section .form-grop label {
  width: 40%;
}

body.has-webview .billing-detail-section .form-grop .input-container {
  width: 60%;
}

body.has-webview .billing-detail-section .form-grop input {
  border-bottom-width: 0;
}

.billing-detail-section .form-grop input:disabled {
  background-color: transparent;
}

.update-my-plan-text {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #3eb5ab;
}

/* START - select services plan css */

@media screen and (max-width: 480px) {
  .hide-when-desktop {
    display: block !important;
  }

  .hide-when-mobile {
    display: none;
  }

  .update-my-plan-text {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .select-your-plan-font {
    font-size: 20px !important;
  }

  .selected-address-display {
    font-size: 14px !important;
  }

  .selected-change-button {
    font-size: 14px !important;
  }

  .plan-container {
    min-width: 270px;
  }

  .pickup-font {
    font-weight: 400 !important;
    font-size: 12px !important;
    padding-top: 5px !important;
    text-align: center !important;
  }

  .pickup-freq-toggle-container {
    /* width: 270px !important; */
    width: 291px !important;
  }

  .pickup-freq-toggle-highlight-left {
    font-size: 12px !important;
    padding-top: 7px !important;
  }

  .pickup-freq-toggle-not-highlight {
    font-size: 12px !important;
    padding-top: 9px !important;
  }

  .pickup-freq-msg {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1199px) {
  .top-menu-display {
    display: none !important;
  }

  .top-menu-hamburger-display {
    display: block !important;
  }

  .address-cards .address-card label {
    font-size: 14px;
  }
}

.top-menu-hamburger-display {
  display: none;
}

.display-linebreak {
  white-space: pre-line;
}

.hide-when-desktop {
  display: none;
}

.txt:hover {
  text-decoration: underline;
}

.colorFontBlack {
  color: #000000;
}

.colorFontWhite {
  color: #ffffff;
}

.select-your-plan-font {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: #000000;
}

.selected-address-display {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}

.selected-change-button {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #3eb5ab;
}

.plan-container-annual {
  margin: 8px;
  max-width: 270px;
  max-height: 481px;
  background: #3eb5ab;
  border-radius: 0px 30px;
}

.plan-container {
  margin: 8px;
  max-width: 270px;
  max-height: 481px;
  background: rgba(237, 237, 237, 1);
  border-radius: 0px 30px;
}

.plan-hover-murph-parent {
  position: relative;
}

.plan-selection-save-annual-parent {
  position: absolute;
  right: 30px;
}

.plan-selection-save-annual-child {
  text-align: center;
  position: absolute;
  margin-top: -10px;
  width: 68px;
  height: 79px;
  /* left: 165px; */
  left: -60px;
  top: 293px;

  background: #fdc815;
  color: white;
  border-radius: 10px;
  margin-bottom: -6%;
  top: -1%;
  /* right: 64%; */
  z-index: 100;
}

.plan-selection-save-font {
  font-weight: 600;
}

.plan-selection-save-amount {
  font-weight: 700;
}

.plan-type-display-annual {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

.service-type-display {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

.month-cost-display {
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

.monthly-dash-display {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

.pay-today-display {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.04em;
}

.plan-included-in-plan-display {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */
  letter-spacing: 0.05em;
  padding: 0px 0px 0px 25px;
  text-align: left;
  margin-top: 40px;
}

.plan-button-position {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.current-addon-button {
  width: 240px;
  height: 70px;
  left: 448px;
  top: 529px;
  background: #ffffff;
  border: 1px solid #3eb5ab;
  border-radius: 20px;
  font-size: 18px;
  color: #3eb5ab;
}

.current-plan-button {
  width: 251px;
  height: 80px;
  left: 274px;
  top: 681px;
  color: #3eb5ab;
  background: #ffffff;
  border: 1px solid #3eb5ab;
  border-radius: 20px;
  font-size: 18px;
}

.current-plan-header {
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 270px;
  height: 35px;
  left: 0px;
  padding-top: 5px;
  background: #fdc815;
  border-radius: 0px 30px 0px 0px;
}

.plan-button-selected {
  margin: 5px;
  padding: 6px 70px 6px 70px;
  border-radius: 20px;
  border: 1px solid #3eb5ab;
  background: #3eb5ab;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  font-family: Quicksand;
  line-height: 25px;
  letter-spacing: 0.05em;
}

.plan-button-selected-annual {
  margin: 5px;
  padding: 6px 70px 6px 70px;
  border-radius: 20px;
  border: 1px solid #3eb5ab;
  background: #fdc815;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  font-family: Quicksand;
  line-height: 25px;
  letter-spacing: 0.05em;
}

.plan-button {
  margin: 5px;
  padding: 6px 80px 6px 80px;
  border-radius: 20px;
  border: 1px solid #3eb5ab;
  background: #ffffff;
  color: #3eb5ab;
  font-weight: 500;
  font-size: 18px;
  font-family: Quicksand;
  line-height: 25px;
  letter-spacing: 0.05em;
}

.plan-continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pickup-font {
  font-weight: 500;
  font-size: 16px;
  padding-top: 7px;
  padding-right: 5px;
  text-align: right;
  padding-bottom: 10px;
}

.pickup-freq-toggle-container {
  width: 391px;
  /* width: 345px; */
  height: 40px;
  border: 1px solid rgba(62, 181, 171, 0.5);
  border-radius: 20px;
}

.pickup-freq-toggle-highlight-left {
  text-align: center;
  width: 196px;
  height: 34px;
  top: 2px;
  max-width: 50.5%;
  /* width: 49%; */
  /* margin-left: 0.5%; */
  margin-left: -3.5%;
  margin-right: -3.5%;
  /* margin-right: 0.5%; */
  padding-top: 5px;
  background: #3eb5ab;
  border-radius: 20px;
  color: white;
}

.pickup-freq-toggle-not-highlight {
  text-align: center;
  max-width: 49.5%;
  font-size: 16px;
  padding-top: 6px;
  border-radius: 20px;
  color: #666666;
}

.return-to-og-page-btn {
  font-weight: 600;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #3eb5ab;
  font-size: 18px;
}

.return-to-og-page-enabled-btn {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  line-height: 25px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #2a9b8d;
}

.continue-btn-disabled {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  padding: 1%;
  outline: none;
  border-radius: 20px;
  border-color: rgb(196, 196, 196);
  border: 0.5px solid rgb(196, 196, 196);
  color: rgb(196, 196, 196);
  background: white;
}

/* END - select services plan css */

/* START = add on services css */

.addon-optional-word-font {
  font-size: 14px;
  font-weight: 400;
}

.display-linebreak {
  white-space: pre-line;
}

.pickup-freq-msg {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}

.add-on-container {
  max-height: 321px;
  margin: 8px;
  max-width: 270px;
  background: rgba(237, 237, 237, 1);
  border-radius: 0px 30px;
}

.addon-button {
  margin: 5px;
  padding: 6px 84px 6px 84px;
  border-radius: 20px;
  border: 1px solid #3eb5ab;
  background: #ffffff;
  color: #3eb5ab;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.05em;
}

.addon-button-selected {
  margin: 5px;
  padding: 6px 73px 6px 73px;
  border-radius: 20px;
  border: 1px solid #3eb5ab;
  background: #3eb5ab;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  font-family: Quicksand;
  line-height: 25px;
  letter-spacing: 0.05em;
}

.addon-continue-button-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}

.summary-continue-button-padding {
  padding-bottom: 50px;
}

.previous-page-btn {
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: #3eb5ab;
  background: white;
  font-weight: 600;
}

.previous-page-enabled-btn {
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #3eb5ab;
  font-weight: 600;
}

.continue-page-btn {
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #3eb5ab;
  font-weight: 600;
}

.continue-page-hovered-btn {
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #2a9b8d;
  font-weight: 600;
}

/* END = add on services css */

.recollect-widget {
  min-height: 550px;
  height: auto;
  max-height: 700px;
}

/* Contact us page css */
.contact-main {
  font-family: "Quicksand";
  border-radius: 20px;
  position: relative;
}

.contact-main h3 {
  margin: 0;
  padding: 0;
}

.contact-main h4 {
  font-size: 18px;
  font-weight: 500;
  /* margin-bottom: 0; */
  margin-top: 2rem;
}

.contact-form-fields {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.contact-form-fields>input {
  border-bottom: 1px solid #c4c4c4;
}

.contact-form-fields>input::placeholder {
  color: #c4c4c4;
}

.contact-form-fields select {
  /* margin-left: -5px; */
  /* color: #c4c4c4; */
  color: black;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 5px;
}

.specialist {
  max-width: 40%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.contact-file-button {
  max-width: fit-content;
  background-color: #ededed;
  border: 1px solid #a5a5a5;
  padding: 2px 10px;
}

.save-button {
  color: var(--primary2);
  font-size: 20px;
  font-weight: 600;
  width: 260px;
  background-color: white;
  border: 1px solid var(--primary2);
  border-radius: 20px;
  text-align: center;
  align-self: center;
  margin-top: 2rem;
  padding: 0.3rem 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form-fields>input[type="date"] {
  width: 200px;
}

.contact-main>img {
  position: absolute;
  top: -47px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

input[type="radio"]:after {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  top: -5px;
  /* left: -1px; */
  position: relative;
  /* background-color: #d1d3d1; */
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid black;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  /* top: -3px; */
  left: -1px;
  position: relative;
  background-color: black;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* START = Change Plan Summary  */

.summary-container {
  position: relative;
  max-width: 330px;
  min-height: 552px;
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.summary-container-padding {
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 35px;
}

.summary-container-header {
  width: 330.02px;
  height: 0px;
  left: 806px;
  top: 240px;
  border: 5px solid #3eb5ab;
}

.summary-container-title {
  /* margin-bottom: 15px; */
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.summary-service-display-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.summary-container-message {
  margin-top: 70px;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #000000;
}

.summary-error-message {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.08em;
  color: red;
}

.summary-divider {
  margin-bottom: 10px;
  border: 0.01px solid #c4c4c4;
}

.summary-body-text {
  margin-top: 0.05px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
}

.summary-total-divider {
  float: right;
  right: 603px;
  width: 73.03px;
  border: 0.01px solid #666666;
}

.summary-total-amount-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.summary-total-amount-position {
  margin-left: 2%;
  margin-right: 2%;
  height: 42px;
  background: rgba(62, 181, 171, 0.2);
  border-radius: 10px;
}

.summary-total-amount-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.summary-plan-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.summary-total-amount-padding {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
}

.summary-total-amount-margin {
  margin-bottom: 70px;
}

.summary-back-btn-top-padding {
  padding-top: 20px;
}

.summary-success-container {
  margin-top: 150px;
  width: 930px;
  height: 395px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.summary-success-content-padding {
  padding: 55px 55px 0px 55px;
}

.summary-success-header {
  padding-top: 30px;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.summary-success-price {
  margin-bottom: 35px;
  padding-top: 20px;
  font-weight: 200;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.summary-success-next-pick-up {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.summary-success-murph-parent {
  position: relative;
  display: flex;
  justify-content: center;
}

.summary-success-murph-child {
  position: absolute;
  margin-top: -72px;
  z-index: 100;
}

.summary-complete-btn-padding-top {
  padding-top: 45px;
}

.summary-complete-msg-padding {
  padding-top: 30px;
  font-size: 18px;
}

.summary-complete-btn {
  font-size: 18px;
  letter-spacing: 1px;
  height: 39px;
  width: 215px;
  outline: none;
  border-radius: 20px;
  border-color: #3eb5ab;
  border: 0.5px solid #3eb5ab;
  color: white;
  background: #3eb5ab;
  font-weight: 600;
}

/* END = Change Plan Summary  */

.image-title {
  display: flex;
  width: auto;
  background-color: #dee2e6;
  border-radius: 10px;
  justify-content: space-between;
  padding: 10px;
  margin-top: 8px;
}

.image-title h5 {
  margin-bottom: 0px;
}

.choose-files-button {
  width: fit-content;
  padding: 5px 15px;
  border: 1px solid #a5a5a5;
}

.top-menu-links {
  padding: 0px 30px 0px 10px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #666666;
}

.hamburger-menu-display {
  padding: 0px 15px 30px 15px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #666666;
}

.top-menu-hamburger-color {
  color: #3eb5ab;
  font-size: 35px;
}

.murph-image-setting {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.murph-width {
  flex-basis: 50%;
}

.progress-bar {
  background-color: #3eb5ab !important;
}

.label-services,
.input-services {
  width: 35%;
  font-family: "Quicksand";
  font-style: normal;
  /* font-weight: 600; */
  /* font-size: 18px; */
  font-size: 20px;
  line-height: 16px;
  color: #666666;
  display: block;
  margin-bottom: 0rem;
}

.services-field {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
}

.input-services {
  width: 65%;
  color: #000;
  font-size: 18px;
  line-height: 22px;
}

.services-field .input-services p {
  margin-bottom: 0px;
  line-height: 30px;
}

.services-list-nested {
  width: 65%;
  margin-left: 35%;
}

.services-list-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.services-list-item .input-services {
  width: 100%;
}

.services-list-item .services-field {
  width: 30%;
}

.services-list-item .remove-addon {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.services-list-option-title {
  display: flex;
}

.services-list-option-title>span:first-child {
  width: 30%;
}